.testimonials{
  color: #fff;
  background-color: $primary;
  padding: 2rem 0rem;
  overflow: hidden;
  .swiper-slide{
    > div{
      max-width: 100%;
      width: inherit;
    }
  }
  .title{
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 500;
    max-width: none;
   }


  .swiper-scrollbar {
    border-radius: 0;
    height: 1px;
    width: 100%;
     margin: 0 auto;

     position: relative;
     z-index: 11;
     background: rgba(255,255,255,.2);
     .swiper-scrollbar-drag{
       background: #fff;
     }
   }

}

@media (min-width: 992px) {
  .testimonials{
     padding-top: 4rem;
     padding-bottom: 4rem;
    .swiper-scrollbar {
      margin-top:2rem;
    }
  }
}
