.card{

  &.is-centered{
    .card-image__content{
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
  &-image{
    text-decoration: none;
    display: block;
    cursor:pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    color: #fff;
    &:hover{
      color: #fff;
      img{
        transform: scale(1.1);
      }
      .card-image__content{
        background: rgba($primary, .5);
      }
    }
    &__content{
      background: rgba($primary, .1);
      transition: background-color .5s;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position:absolute;
      z-index:0;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
    &__img{
      img{
        transition: transform .5s;
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &__bg{
      background:no-repeat center;
      background-size:cover;
      position:absolute;
      z-index:0;
      width:100%;
      height:100%;
      top:0;
      left:0;
      opacity:0.5;
    }
    &__title{
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0;
      line-height: 1.2;
      @media (min-width: 992px){
        font-size: 2rem;
      }
    }
    &__subtitle{
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }

  }
  &-content{
    &__title{
      font-weight: bold;
      font-size: 1.1rem;
      a{
        text-decoration: none;
      }
    }
  }
  .card-image + .card-content{
    margin-top: 1rem;
  }
  .card-content + .card-footer{
    margin-top: 1rem;
  }
  .card-content button{
    margin-top: 1rem;
  }
  &.featured{
    .card-image:hover .card-hover{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &:before{
        opacity: .7;
      }
    }
    .card-image{
      margin-bottom: 0;
      .card-hover{
        display: none;
        &:before{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $primary;
          content: '';
          z-index: 0;
          opacity: 0;
          transition: opacity .25s;
        }
        .content{
          z-index: 1;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
          position: relative;
          align-items: center;
          max-width: 300px;
          p{
            margin-top: 1rem;
            font-size: 1.5rem;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
    .card-content{
      margin-top: 0;
      padding: 1rem;
      background: $primary;
      color: #fff;
      .card-content__title a:hover{
        color: #826442;
      }
      p{
        display: flex;
        align-items: center;
        svg{
          width: 20px;
          height: 20px;
          margin-right: .5rem;
        }
      }
    }
  }
}
