.read-more-container{
  .read-more{
    display: none;
  }
  &.is-active{
    .read-more{
      display: block;
      p,ul,ol{
        text-align: left !important;
      }
    }
  }
}
