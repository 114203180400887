$menu-item-color: $text !default;
$menu-item-radius: $radius-small !default;
$menu-item-hover-color: $text-strong !default;
$menu-item-hover-background-color: $background !default;
$menu-item-active-color: $link-invert !default;
$menu-item-active-background-color: $link !default;

$menu-list-border-left: 1px solid $border !default;

$menu-label-color: $text-light !default;

.menu{
  font-size: $size-normal;
  // Sizes
  &.is-small{
    font-size: $size-small;
  }
  &.is-medium{
    font-size: $size-medium;
  }
  &.is-large{
    font-size: $size-large;
  }
}

.menu-list{
  line-height: 1.25;
  a{
    // color: $menu-item-color;
    display: inline-block;
    padding: 0.25rem 0;
    &:hover{
      color: $menu-item-hover-color;
    }
    // Modifiers
    &.is-active{
      background-color: $menu-item-active-background-color;
      color: $menu-item-active-color;
    }
  }
  li{
    ul{
      border-left: $menu-list-border-left;
      margin: 0.75em;
      padding-left: 0.75em;
    }
  }
}

.menu-label{
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: inherit;
  &:not(:first-child){
    margin-top: 1rem;
  }
  &:not(:last-child){
    margin-bottom: 1rem;
  }
}
