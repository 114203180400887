.brand_logo{
  width:80px;
  position:relative;
  height:100%;
  margin: 0 -0.75rem;
  .brand_logo_image{
    position:absolute;

    left:0; right:0;
    color:#b39066;
    background:#302736;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    svg{
      max-width:100%;
      height:auto;
      display:block;
    }
  }
  @media (min-width: 992px){
    width:170px;
    .brand_logo_image{
      top:-0.5rem;
      padding:0;
    }
  }
}


.corporate-banner{
  color:white;
  position:relative;
  background:$primary;

  padding:1rem;
  @media (min-width: 600px){
    padding:2rem;
  }
  @media (min-width: 992px){
    padding:3rem;
    border:5px solid $link;
  }
  &__background{
    opacity:.2;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:0;
    background: url('../images/banner-corporate.jpg') no-repeat center;
    background-size: cover;
  }
  &__content{
    position:relative;
    z-index:1;
    display: flex;
    flex-wrap: wrap;
  }
  &__icon{
    width:36px;
    height:36px;
    margin-right: 1rem;
    @media (min-width: 600px){
      width:48px;
      height:48px;
    }
    @media (min-width: 992px){
      width:80px;
      height:80px;
      margin-bottom: 1rem;
    }
  }
  &__title{
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.2rem;
    @media (min-width: 992px){
      font-size: 1.3rem;
    }
  }
  &__box{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;

    @media (min-width: 600px){
      width: 50%;
    }
    @media (min-width: 992px){
      width: 25%;
      display: block;
      text-align: center;
    }
  }
  &__description{
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    .button{
      vertical-align: middle;

    }
    .is-text{
      @media (max-width: 600px){
        display: block;
      }
      margin: 0 .5rem;
      margin-top: .5rem;
    }
  }
}

.social-icon{
  width:24px; height:24px;
  display: inline-block; margin-right: 1rem;
}
picture{
  img{
    max-width:100%;
    display:block;
    height:auto;
    margin:0 auto;
  }
  &.picture{
    display: block;
    position: relative;
    img{
    }
    &:after{
      display: block;
      content: '';
      position:absolute;
      z-index: 1;
      width:100%;
      height:100%;
      left:0;
      top:0;
    //  background: rgba($primary,0.3);
      background-image: linear-gradient(rgba($primary,0),rgba($primary,0.5));
    }
  }
}

a{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}
.button{
  text-decoration: none;
}
.navbar{
  a{
    text-decoration: none;
  }
}

h1,h2,h3,h4,h5,h6{
  a{
    color: inherit;
  }
}

@media (min-width: 992px){
 .title{
   font-size: 2rem;
   &.is-1{
     font-size: 2.5rem;
   }
 }
}


.navbar-start {
  .icon{
    color: $link;
    margin-right: 1rem;
    line-height: 1;
  }
  .heading{
    font-weight: 600;
    display: block;

  }
}

.content:not(:last-child){
  margin-bottom: 0;
}

.logo{
  margin: .5rem 0;
  display: block;
  width: 130px;
  margin-right: 1rem;
  img{
    width: 100%;
    height: auto;
    display: block;
  }
}
.container{
  position: relative;
  padding: 0 1rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 600px){
    padding: 0 2rem;
  }
  @media (min-width: 1500px){
    .container-inner{
      margin: 0 -3rem;
    }
  }
}


.hero{
  position: relative;
  .hero-image{
    z-index: 0;
    position: absolute;
    left: 0;
    top:0;
    background: no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: .5;
  }
}

.subtitle{
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: 1px;
  color: inherit;
  padding-bottom: 1rem;
}
.title{
  max-width: 900px;
  padding-bottom: 2rem;
}
.space{
  height: 3rem;
  @media (min-width: 992px){
    height: 6rem;
  }
}
.hero-footer {
  .icon{
    color: $link;
  }
  .heading{
    padding: 1rem;
    font-weight: 600;
    display: block;
  }
}

footer{
  padding: 2rem 0;
  background: #fff;
}
html{
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
    height: 100%;
    background: $primary;
  body{
    overflow: hidden;
    opacity: 1;
    background: #fff;

  }
}

body, html{
  min-height: 100%;

}
#app{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  footer{
    margin-top: auto;
  }
}

.popup{
  position:fixed;
  z-index:16000002;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  &__close{
    cursor:pointer;
    color:#fff;
    padding:1rem;
    background:transparent;
    border:none;
    outline: none;
    position:absolute;
    right:0;
    top:0;
    z-index:3;
  }
  &__container{
    background: #fff;
    z-index:2;
    position:
    relative;
    width: 100%;
    height: 100%;
    max-width:700px;
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
    .popup__content{
      padding: 1rem;
      color: $body-color;
    }
    @media (min-width: 700px){
      .popup__close,
      .popup__content{
        padding: 2rem;
      }
      max-height:90%;
      height: auto;
    }


  }
}
