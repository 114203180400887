.latest-news{
  a{
    text-decoration: none;
  }
  &-item{
    padding: 1rem 0;
    display: block;
    font-weight: 500;
  }
  &-date{
    padding-right: 2rem;
    color: $link;
    display: block;
  }
  &-title{
    display: block;
    color: $primary;
  }
  li:not(:last-child){
    .latest-news-item{
      border-bottom: 1px solid #f2f2f2;
      padding: 1rem 0;
    }
  }
}

@media (min-width: 992px){
  .latest-news{
    &-item{
      display: flex;
    }
  }
}
