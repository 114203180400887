.masonry {
  column-count: 1;
  margin: 0 auto;
  @media only screen and (min-width: 1024px) {
    column-count: 4;
  }
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    column-count: 3;
  }
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    column-count: 2;
  }
  .item {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: .5rem;
  }
}
