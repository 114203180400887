.dark-about{
  background-color: rgb(48, 39, 54);
  color: #fff;
  padding: 4rem 0;
  .title{
    margin-bottom: 1.5rem;
    padding-bottom: 0;
    font-size: 1.5rem;
  }
}
