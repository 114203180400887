.iam-collapse{
  border-bottom: 1px solid rgba($body-color,.1);
  .iam-collapse-item__header {
    display: flex;
    align-items: center;
    height: 3rem;
    line-height: 3rem;
    user-select: none;
    cursor: pointer;
    &:active{
      cursor: pointer;
    }
    border-top: 1px solid rgba($body-color,.1);
    font-weight: 500;
    outline: none;
    &.is-active {
      border-bottom-color: transparent;
    }
    &.focusing:focus:not(:hover){
      color: rgba($body-color,.5);
    }
    .header-icon{
      margin-left: .5rem;
    }

  }
.iam-icon-chevron-right{
  position: relative;
  &:after{
    border: 3px solid $link;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    width: 0.625em;
  }
}
  .iam-collapse-item__arrow {
    margin: 0 .5rem 0 auto;
    transition: transform .25s;
    &.is-active {
      transform: rotate(90deg);
    }
  }
  .iam-collapse-item__wrap {
      will-change: height;
  }
  .iam-collapse-item__content {
    padding-bottom: 1rem;
    user-select: none;
  }
}

.iam-collapse{
  .iam-collapse-item {
    .iam-collapse{
    //  border-bottom: none;
      .iam-collapse-item__content,
      .iam-collapse-item__header {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    // margin-bottom: -1rem;

    }
  }
}
.iam-collapse .iam-collapse{
  border-left: 1px solid rgba($body-color,.1);
  border-right: 1px solid rgba($body-color,.1);
}
// .iam-collapse .iam-collapse > .iam-collapse-item:last-child > .iam-collapse-item__header,
.iam-collapse .iam-collapse > .iam-collapse-item:last-child > .iam-collapse-item__wrap{
  // border-bottom: none;
}

.iam-collapse{
  .iam-collapse-item__header.is-active > .iam-badge > .iam-badge__content{
      display: none;
  }
}
