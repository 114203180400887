// color variable map
$colors: (
  facebook: (
    bg: #3b5998,
    color: #fff
  ),
  twitter: (
    bg: #1da1f2,
    color: #fff
  ),
  pinterest:(
    bg: #bd081c,
    color: #fff
  ),
  linkedin: (
    bg: #007bb5,
    color: #fff
  ),
  googleplus: (
    bg: #db4437,
    color: #fff
  ),
  whatsapp: (
    bg: #25d366,
    color: #fff
  ),
  reddit: (
    bg: #ff4500,
    color: #fff
  ),
  skype: (
    bg: #00aff0,
    color: #fff
  ),
  vk: (
    bg: #4a76a8,
    color: #fff
  ),
  line: (
    bg: #00b900,
    color: #fff
  ),
  telegram: (
    bg: #0088cc,
    color: #fff
  ),
  weibo: (
    bg: #fa2f2f,
    color: #fff
  ),
  odnoklassniki: (
    bg: #ee8208,
    color: #fff
  ),
);

@function color($network, $color) {
  @return map-get(map-get($colors, $network), $color);
}

@each $key,$val in $colors{
  .has-#{$key}-background{
    background-color: color($key, bg);
    border-color: transparent;
    color: color($key, color);
    &:active, &.is-active{
      background-color: lighten(color($key, bg), 5%);
      border-color: color($key, bg);
      color: color($key, color);
    }
    &:focus, &.is-focused,
    &:hover, &.is-hovered{
      background-color: lighten(color($key, bg), 5%);
      border-color: color($key, bg);
      color: color($key, color);
    }
  }
}
