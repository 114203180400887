
$column-gap: 1.5rem;
// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;

// Update Bulma's global variables
$family-sans-serif: "Roboto", sans-serif;
$primary: #302736;
$link: #826442;
$body-color: #212121;
//$widescreen-enabled: false;
//$fullhd-enabled: false;
// $subtitle-weight: 400;

// Update some of Bulma's component variables
$body-background-color: #fff;
$control-border-width: 1px;
// $input-border-color: $primary;
$input-shadow: none;

$strong-color: inherit;
$title-color: inherit;
$title-weight: 500;
$title-size: 1.5rem;

$size-1: 2rem;

$radius: 2px;

$navbar-item-img-max-height: 120px;

$dropdown-content-background-color: #fff;
$dropdown-content-radius: $radius;
