.swiper-property{
  .swiper-container{
    overflow: visible;
  }
  .swiper-wrapper{
    align-items: center;
  }
  .swiper-pagination{
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
      background: #fff;
      border: 1px solid $link;
      opacity: 1;
      &:not(:last-child){
        margin-right: .25rem;
      }
      &-active{
        background: $link;
      }
    }
  }
}
