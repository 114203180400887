.property-column{
  .information{
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  .property:nth-child(even){
    background-color: rgb(248, 247, 245);
    .information{
      background-color: rgb(248, 247, 245);
    }
  }
  .property{
    padding: 3rem 0;
    &:first-child{
      padding-top: 0;
    }
  }
  .columns{
    align-items: center;
  }
  .information{
    .title{
      font-size: 2rem;
      padding-bottom: 2rem;
      margin-bottom: 0;
      font-weight: bold;
    }
    ul {
      li{
        border-bottom: 1px solid rgba(0,0,0,.25);
        padding: .5rem 0;
        display: flex;
        align-items: center;
        svg{
          margin-right: .5rem;
        }
      }
    }
    .text{
      margin: 1.5rem 0;
    }
    .price{
      display: block;
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
  }
}
