.enquiry-form{
  background-color: $primary;
  padding: 4rem 0;
  color: #fff;
  position: relative;
  .background-image{
    position: absolute;
    right: 0;
    top: 0;
    opacity: .1;
    @media only screen and (max-width: 1023px) {
      svg{
        width: 200px;
        height: 200px;
      }
    }
  }
  .label{
    color: #fff;
  }
}
