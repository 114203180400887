.vue-lb-box {
  width: 100%;
}
.vue-lb-overlay{
  background-color: rgba($primary, 0.95);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transition: opacity .3s ease;
}
.vue-lb-open{
  .vue-lb-overlay{
    opacity: 1;
  }
}
.vue-lb-container {
  z-index: 1111;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0px;
  width: 100%;
}

.vue-lb-content {
  margin-bottom: 60px;
  max-width: 1200px;
  z-index: 2;
  position: relative;
}

.vue-lb-button-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  outline: none;
  position: absolute;
  z-index: 3;
  top: 0px;
  right: 0;
  vertical-align: bottom;
  height: 70px;
  padding: 10px;
  width: 70px;
}

.vue-lb-figure {
  z-index: 2;
  margin: 0px;
  display: block;
  position: relative;
}

img.vue-lb-modal-image {
  background: #36343f;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  max-height: calc(100vh - 140px);
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vue-lb-info {
  visibility: initial;
  position: absolute;
  bottom: 0;
  padding: .5rem 1rem;
  color: white;
  background-color: rgba(#36343f, 0.5);
  width: 100%;
}


.vue-lb-footer {
  box-sizing: border-box;
  color: white;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  left: 0px;
  line-height: 1.3;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
}

.vue-lb-footer-info {
  display: block;
  flex: 1 1 0;
}

.vue-lb-footer-count {
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.85rem;
  padding-left: 1rem;
}

.vue-lb-thumbnail {
  bottom: 10px;
  height: 50px;
  padding: 0 50px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.vue-lb-modal-thumbnail {
  background-position: center;
  background-size: cover;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.2);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px;
  overflow: hidden;
  width: 50px;
}

.vue-lb-modal-thumbnail-active {
  background-position: center;
  background-size: cover;
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px white;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px;
  overflow: hidden;
  width: 50px;
}

.vue-lb-thumbnail-arrow {
  color: #fff;
  height: 54px;
  width: 40px;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  z-index: 3;
  top: 50%;
  -webkit-touch-callout: none;
  user-select: none;
  height: 50px;
  margin-top: -25px;
  width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vue-lb-thumbnail-left {
  left: 10px;
}

.vue-lb-thumbnail-right {
  right: 10px;
}

.vue-lb-arrow {
  z-index: 3;
  color: #fff;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  -webkit-touch-callout: none;
  user-select: none;
  height: 120px;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vue-lb-left {
  left: 1rem;
}

.vue-lb-right {
  right: 1rem;
}

.vue-lb-thumbnail-wrapper {
  bottom: 1rem;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  right: 0;
  text-align: center;
  top: auto;
}

@media (min-width: 500px) {
  .vue-lb-thumbnail-arrow {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .vue-lb-arrow {
      width: 70px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.no-scroll {
  overflow-y: hidden;
}
